import i18n from "../../i18n"
import uuid from "../../helpers/uuid"
import { userLang } from "@/helpers/globals"
function moneyFilter(value) {
  return parseFloat(value.toFixed(3))
    .toFixed(2) // always two decimal digits
    .replace(".", ",") // replace decimal point character with ,
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
}

const getDefaultState = () => ({
  old: null,
  occasion: null,
  gift: null,
  options: null,
  prices: null,
  action: null,
  force: 0,
  errors: {
    to: null,
    from: null,
    price: null,
    email: null,
    option: null,
    amount: null,
    locale: null,
    message: null,
    excelFile: null,
    delivery_date: null,
    detailed_reference: null,
    thankyou_video: null,
  },
  recipientListErrors: [],
  quantity: 1,
  orderQuantityConstraints: {},
  settings: {},
  countries: {},
  isKudos: false,
  kudos: {},
  customPrice: "",
  recipientType: "",
  showConfirm: false,
  showBlocking: false,
  selectedPriceId: null,
  selectedOptionId: null,
  directlyToRecipient: false,
  hasVideo: false,
  pendingUploads: 0,
  videoUrl: null,
  showLimitedQuantityWarning: false,
  form: {
    detailed_reference: "",
    delivery_date: "",
    message: "",
    locale: "",
    email: "",
    from: "",
    to: "",
    recipient_first_name: "",
    recipient_last_name: "",
    recipient_full_name: "",
    street: "",
    street_number: "",
    bus: "",
    zipcode: "",
    city: "",
    country: "",
  },
  groups: [],
  locales: [],
  excelUploadStatus: null,
  excelErrors: [],
  errorFlag: null,
  uploadedExcelPath: "",
  vendorInCart: null,
  directlyToRecipientInCart: null,
  loadColorlab: false,
  colorlabCustomizationId: null,
  colorlabCustomizationToken: null,
  priceMatrices: [],
  hasGreetingCard: false,
})

const state = getDefaultState()

const getters = {
  isKadonationVoucher: state => {
    const gift = state.gift.default || state.gift
    return gift?.is_kadonation_voucher
  },
  isKadonationGreetingCard: state => {
    const gift = state.gift.default || state.gift
    return gift?.is_kadonation_greeting_card
  },
  isSendKadonationGreetingCardToVendor: state => {
    const gift = state.gift.default || state.gift
    return (
      !!gift?.is_send_kadonation_greeting_cards_to_vendor_enabled &&
      !!gift?.vendor_linked_kadonation_greeting_card
    )
  },
  personalizable: state => {
    return state.gift?.default?.personalizable || state.gift?.personalizable
  },
  colorlabProductId: (state, getters) => {
    const giftHasOptions = getters.hasOptions && getters.selectedOption
    const pivot = getters.selectedOption?.pivot

    // check for pivot first
    if (giftHasOptions && pivot && pivot.colorlab) {
      return pivot.colorlab_product_id
    }
    // then check for option
    if (giftHasOptions && getters.selectedOption.colorlab) {
      return getters.selectedOption.colorlab_product_id
    }
    // in case there are no options
    if (state.gift.default?.colorlab) {
      return state.gift.default.colorlab_product_id
    }
    return null
  },
  hasFixedPrice: (state, getters) => {
    if (getters.hasOptions && state.selectedOptionId) {
      return !!getters.selectedOption.has_fixed_price
    }

    return state.gift.default ? !!state.gift.default.has_fixed_price : !!state.gift.has_fixed_price
  },
  fixedPrice: (state, getters) => {
    if (!getters.hasFixedPrice) return 0

    if (getters.hasOptions) {
      if (!state.selectedOptionId) return 0

      return (
        getters.selectedOption.price.regular_price +
        (getters.selectedOption.price_is_addon_to_gift_price
          ? state.gift.default.price.regular_price
          : 0)
      )
    }

    return state.gift.default
      ? state.gift.default.price.regular_price
      : state.gift.price.regular_price
  },
  hasOptions: state => {
    if (!state.options) {
      return false
    }

    return state.options && state.options.length > 0
  },
  hasManyOptions: (state, getters) => {
    return getters.hasOptions && state.options.length > 8
  },
  isVendorGift: state => {
    const vendor = state.gift.default?.vendor ?? state.gift.vendor
    if (vendor) {
      return vendor.id !== 1
    } else {
      // This is a fix for shared order page -> personalization
      return state.gift.vendor_id !== 1
    }
  },
  selectedOption: state => {
    return state.selectedOptionId !== null
      ? state.options.find(option => state.selectedOptionId == option.id)
      : null
  },
  isDigital: (state, getters) => {
    if (getters.hasOptions) {
      return getters.selectedOption?.is_digital
    }

    return state.gift?.default?.is_digital
  },
  isDigitalDirect: (state, getters) => {
    return state.directlyToRecipient && getters.isDigital
  },
  isNonDigitalDirectHomeDelivery: (state, getters) => {
    if (state.settings.home_delivery_enabled == 0) {
      return false
    }

    return state.directlyToRecipient && !getters.isDigital
  },
  slider: (state, getters) => {
    return {
      direction: "left",
      key: getters.selectedOption ? `option-${getters.selectedOption.id}` : `gift-${state.gift.id}`,
      alt: getters.selectedOption ? getters.selectedOption.name[userLang] : state.gift.default.name,
      src: getters.selectedOption ? getters.selectedOption.image : state.gift.images[0],
    }
  },
  selectedPrice: state => {
    if (state.selectedPriceId === null) return null
    return state.prices.find(price => price.id === state.selectedPriceId)?.value
  },
  price: (state, getters) => {
    const price = parseInt(getters.selectedPrice || state.customPrice)
    return isNaN(price) ? 0 : price
  },
  orderQuantityConstraints: state => state.orderQuantityConstraints,
  groups: state => state.groups,
  giftImages: state => state.gift?.images,
  showVendor: state => {
    return state.gift.vendor ? state.gift.vendor.id != state.gift.vendor.default_id : false
  },
  giftPriceRange: state => {
    if (!state.gift?.price_range) return ""

    const range = Object.values(state.gift.price_range).filter(item => item !== null)

    if (range.length === 0) {
      return i18n.global.tc("general.amount-of-choice")
    }

    if (range.length === 1) {
      return range[0] === 0 ? i18n.global.tc("orders.price_free") : `€${moneyFilter(range[0])}`
    }

    return `€${moneyFilter(range[0])} - €${moneyFilter(range[1])}`
  },
  giftPrice: state => {
    if (!state.gift) return null

    // Find a matching price matrix based on the quantity and not related to any of the
    // gift's options
    const priceMatrices = state.priceMatrices
      .filter(({ option_id }) => option_id === null)
      .filter(({ min, max }) => {
        return (
          (min || Number.NEGATIVE_INFINITY) <= state.quantity &&
          (max || Number.POSITIVE_INFINITY) >= state.quantity
        )
      })

    // If there is no price matrix for the gift, get the price from the gift itself
    if (priceMatrices.length === 0) {
      const price = (state.gift.default || state.gift).price

      if (!price) return 0

      // Here we're returning the price (if it is non-zero), otherwise we're returning the
      // handling fee.
      return price.regular_price || price.regular_handling_fee
    }

    const priceMatrix = priceMatrices.pop()

    // Once again, we're returning the price (if it is non-zero), otherwise we're returning the
    // handling fee.
    return priceMatrix.price || priceMatrix.handling_fee
  },
  selectedOptionPrice: (state, getters) => {
    if (!(state.selectedOptionId || getters.hasOptions)) {
      return getters.giftPrice
    }

    return getters.optionPrice(state.selectedOptionId)
  },
  optionPrice: (state, getters) => optionId => {
    const option = state.options.find(o => o.id === optionId)

    if (!option) return null

    const priceMatrices = state.priceMatrices.filter(item => {
      return (
        item.option_id === option.id &&
        (item.min || Number.NEGATIVE_INFINITY) <= state.quantity &&
        (item.max || Number.POSITIVE_INFINITY) >= state.quantity
      )
    })

    const addonPrice = option.price_is_addon_to_gift_price ? getters.giftPrice : 0
    const priceMatrix = priceMatrices.length ? priceMatrices.pop() : null

    let optionPrice = (priceMatrix ? priceMatrix.price : option.price.regular_price) + addonPrice

    if (optionPrice > 0) return optionPrice

    // If the price of an option is 0, return the handling fee.
    return (priceMatrix ? priceMatrix.handling_fee : option.price.regular_handling_fee) + addonPrice
  },
  priceRangeForOption: state => optionId => {
    const option = state.options.find(({ id }) => id === optionId)

    if (!option) return null

    let priceMetricsForOption = state.priceMatrices
      .filter(metric => metric.option_id === optionId)
      .map(({ price }) => price)

    priceMetricsForOption.push(option.price?.regular_price)
    priceMetricsForOption = priceMetricsForOption.sort((a, b) => a - b).filter(Boolean)

    const min = priceMetricsForOption.length > 0 ? priceMetricsForOption[0] : null
    const max =
      priceMetricsForOption.length > 1
        ? priceMetricsForOption[priceMetricsForOption.length - 1]
        : null

    return [min, max].filter(Boolean)
  },
  priceRangeForSelectedOption: (state, getters) => {
    if (!state.selectedOptionId) return null

    return getters.priceRangeForOption(state.selectedOptionId)
  },
  physicalNonVoucherDirect: (state, getters) =>
    state.directlyToRecipient &&
    !(getters.isDigital || getters.isKadonationVoucher || getters.isKadonationGreetingCard),
  emailAddressRequired: (state, getters) =>
    (state.directlyToRecipient && getters.isDigital) || getters.physicalNonVoucherDirect,
  showPersonalizationInputs: (state, getters, rootState, rootGetters) =>
    state.gift.has_greeting_card_addon
      ? rootGetters["sharedOrder/personalizingItem"].has_greeting_card
      : state.gift.personalizable,
}

const mutations = {
  setAttribute: (state, { attribute, value }) => {
    if (!Object.keys(state).includes(attribute)) {
      throw new Error(`${attribute} is not a state variable in the product module.`)
    }

    state[attribute] = value
  },
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setOccasion(state, occasion) {
    state.occasion = occasion
  },
  setGift(state, gift) {
    state.gift = gift
  },
  setOptions(state, options) {
    if (typeof options === "object") {
      options = Object.values(options)
    }

    state.options = options
  },
  setPrices(state, prices) {
    state.prices = prices
  },
  setAction(state, action) {
    state.action = action
  },
  clearErrors(state) {
    state.errors = {
      to: null,
      from: null,
      price: null,
      email: null,
      option: null,
      amount: null,
      locale: null,
      message: null,
      excelfile: null,
      delivery_date: null,
      thankyou_video: null,
    }
  },
  setSpecificError(state, error) {
    state.errors[error.field] = [error.message]
  },
  setRecipientListErrors(state, errors) {
    const errorKeys = Object.keys(errors).filter(key => key.startsWith("messages"))
    const groupedErrors = []
    errorKeys.forEach(errorKey => {
      const split = errorKey.split(".")
      if (split.length !== 3) return
      const row = split[1]
      const field = split[2]
      const groupedError = groupedErrors.find(error => error.row === row)
      if (groupedError) {
        groupedError.errors[field] = errors[errorKey]
      } else {
        groupedErrors.push({
          row,
          errors: {
            [field]: errors[errorKey],
          },
        })
      }
    })
    state.recipientListErrors = groupedErrors
  },
  setSpecificErrorNull(state, field) {
    state.errors[field] = null
  },
  setOld(state, old) {
    state.old = old
  },
  setSelectedOptionId(state, index) {
    state.selectedOptionId = index
  },
  setOrderQuantityConstraints(state, payload) {
    state.orderQuantityConstraints = payload
  },
  updateDirectlyToRecipient(state, bool) {
    state.directlyToRecipient = bool
  },
  updateHasVideo(state, bool) {
    state.hasVideo = bool
  },
  updateVideoUrl(state, url) {
    state.videoUrl = url
    state.errors.thankyou_video = null
  },
  updatePendingUploads(state, { increment = false }) {
    state.pendingUploads += increment ? -1 : 1
  },
  updateRecipientType(state, val) {
    state.recipientType = val
  },
  updateFormData(state, obj) {
    state.form[obj.key] = obj.value
  },
  setUploadedExcel(state, uploadedExcel) {
    state.uploadedExcel = uploadedExcel
  },
  updateSelectedPriceId(state, selectedPriceId) {
    state.selectedPriceId = selectedPriceId
  },
  updateQuantity(state, quantity) {
    state.quantity = quantity
  },
  updateCustomPrice(state, customPrice) {
    state.customPrice = customPrice
  },
  updateForce(state, bool) {
    state.force = bool
  },
  setKudos(state, bool) {
    state.isKudos = bool
  },
  setSettings(state, obj) {
    state.settings = obj
  },
  setCountries(state, obj) {
    state.countries = obj
  },
  updateShowBlocking(state, bool) {
    state.showBlocking = bool
  },
  updateShowConfirm(state, bool) {
    state.showConfirm = bool
  },
  setKudosValue(state, kudos) {
    state.kudos = kudos
  },
  setGroups(state, groups) {
    state.groups = groups
  },
  setLocales(state, locales) {
    state.locales = locales
  },
  setExcelUploadStatus: (state, status) => (state.excelUploadStatus = status),
  setExcelErrors: (state, errors) => {
    if (Array.isArray(errors)) {
      state.excelErrors = errors
    }
  },
  setUploadedExcelPath: (state, path) => (state.uploadedExcelPath = path),
  setVendorInCart: (state, data) => (state.vendorInCart = data),
  setDirectlyToRecipientInCart: (state, data) => (state.directlyToRecipientInCart = data),
  setLoadColorlab: (state, isPersonalizable) => {
    state.loadColorlab = isPersonalizable
  },
  setColorlabData: (state, data) => {
    state.colorlabCustomizationId = data.id
    state.colorlabCustomizationToken = data.token
  },
}

const actions = {
  setSelectedOptionId({ commit }, id) {
    commit("setSpecificErrorNull", "option")
    commit("setSelectedOptionId", id)
  },
  setIsKudos({ commit }, data) {
    commit("setKudos", data)
  },
  setErrors({ commit }, errors) {
    for (let variable in errors) {
      commit("setSpecificError", {
        field: variable,
        message: errors[variable],
      })
    }
  },
  setOld({ state, commit }, data) {
    for (let variable in data) {
      // eslint-disable-next-line no-prototype-builtins
      if (state.form.hasOwnProperty(variable) && data.hasOwnProperty(variable)) {
        if (data[variable]) {
          commit("updateFormData", { key: variable, value: data[variable] })
        }
      }
    }
    if (data.is_directly == "1") {
      commit("updateDirectlyToRecipient", true)
    }
    if (data.price) {
      switch (data.price) {
        case "25":
          commit("updateSelectedPriceId", 1)
          break
        case "30":
          commit("updateSelectedPriceId", 2)
          break
        case "50":
          commit("updateSelectedPriceId", 3)
          break
        case "60":
          commit("updateSelectedPriceId", 4)
          break
        default:
          commit("updateCustomPrice", data.price)
      }
    }
    if (data.option_id) {
      commit("setSelectedOptionId", data.option_id)
    }
    if (data.quantity) {
      commit("updateQuantity", data.quantity)
    }
    // TODO:: This section doesn't seem to commit anything, furthermore it is using un undeclared variable!
    /*if (data.price) {
      let index = function () {
        for (let i = 0; i < state.gift.prices.length; i += 1) {
          if (array[i][value] === value) {
            return i
          }
        }
        return -1
      }
      console.log(index)
    }*/
    if (data.messages) {
      try {
        const parsed = JSON.parse(data.messages).map(recipient => {
          return {
            ...recipient,
            id: recipient.employee_id ? `emp-${recipient.employee_id}` : uuid(),
          }
        })
        commit("recipients/setRecipients", parsed, { root: true })
      } catch (err) {
        console.log(err)
      }
    }
    if (data.is_group === "1") {
      if (data.is_excel === "1") {
        commit("updateRecipientType", "excel")
      } else {
        commit("updateRecipientType", "employees")
      }
    } else {
      commit("updateRecipientType", "form")
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
